export default {
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effortless Afterschool Management For All"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen in Ihrem Buchungsportal!"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername oder E-Mail"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Benutzername oder Passwort"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin erstellen"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Slots verfügbar"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster auswählen"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich Verpflichte Mich"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigung der Verpflichtung"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich Verpflichte Mich' klicken, wird die Kosten der Aktivität Ihrer monatlichen Rechnung für die nächsten 3 Monate hinzugefügt."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich Verpflichte Mich' klicken, wird die Kosten der Aktivität Ihrer jährlichen Rechnung hinzugefügt."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich Verpflichte Mich' klicken, wird die Kosten der Aktivität vierteljährlich in Rechnung gestellt."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich Verpflichte Mich' klicken, wird die Kosten der Aktivität halbjährlich in Rechnung gestellt."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich Verpflichte Mich' klicken, wird die volle Kosten der Aktivität sofort berechnet."])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf speichern"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer veröffentlichen"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Passwort wird vom Benutzer für die Anmeldung verwendet. Stellen Sie sicher, dass es sicher ist. Administratoren können Passwörter festlegen und ändern, aber Benutzer können sie nach der Anmeldung ändern. Administratoren können die Passwörter der Benutzer nicht einsehen."])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrolle"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungsverwaltung"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort aktualisieren"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Passwörter stimmen nicht überein."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich aktualisiert."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren des Passworts."])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails aktualisieren"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettisch"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aktivitäten"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenverwaltung"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortverwaltung"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortenverwaltung"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderansicht"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was gibt's Neues"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerschulische Aktivitäten"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten filtern"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort auswählen"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer auswählen"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorte auswählen"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu den Details"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsansicht"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenansicht"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesansicht"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsbestätigungszentrum"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferienverwaltung"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurzeit keine ausstehenden Buchungen."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigen"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie ", _interpolate(_named("activityName")), " für ", _interpolate(_named("childName")), " buchen?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigen"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie buchen"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zu Ihrer Information: Der Preis für die Aktivität beträgt ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Preis pro Sitzung beträgt ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Die Zahlung wird Ihrer monatlichen Rechnung hinzugefügt."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Monat"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kinder auswählen"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederkehrende Buchung"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Termin jede Woche buchen?"])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich Verpflichte Mich' klicken, wird der Preis der Sitzung zu Ihrer monatlichen Rechnung hinzugefügt."])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigt"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Jahr"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Slot ist bereits gebucht. Bitte wählen Sie eine andere verfügbare Zeit."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Aktivität '", _interpolate(_named("activity")), "' wurde erfolgreich gebucht."])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vierteljährlich"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halbjährlich"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesamt"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Profildaten"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Aktivitäten"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehende Aktivitäten"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil erfolgreich aktualisiert"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen senden"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ist erforderlich"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen wurde gesendet! Bitte überprüfen Sie Ihre E-Mails."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen konnte nicht gesendet werden. Bitte versuchen Sie es erneut."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail und Passwort sind erforderlich"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Felder sind erforderlich, um einen Administrator zu erstellen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansehen"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichern Sie Ihr Konto"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein neues Passwort, um wieder Zugang zu erhalten"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setzen Sie Ihr Passwort zurück"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist erforderlich"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens 8 Zeichen lang sein"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über abgesagte Sitzungen"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen für abgesagte Sitzungen aktivieren"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über neu geplante Sitzungen"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen für neu geplante Sitzungen aktivieren"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Aktualisieren des Profils"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens eine Zahl enthalten"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist zu schwach"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde erfolgreich zurückgesetzt"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht zurückgesetzt werden"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Login"])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kind filtern"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen wurden erfolgreich gespeichert"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung hinzugefügt"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Sitzung wurde erfolgreich hinzugefügt."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung wiederhergestellt"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sitzung wurde wiederhergestellt und wird wie geplant stattfinden."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungsnotizen-Benachrichtigung"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen, wenn eine Notiz für eine Sitzung hinzugefügt oder aktualisiert wird."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Sitzung wurde bereits gebucht. Bitte wählen Sie eine andere."])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Buchung wurde erfolgreich storniert."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung bei neuer Nachricht"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie eine E-Mail-Benachrichtigung, wenn Sie eine neue Nachricht erhalten."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungsbenachrichtigungen"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen über neue Erinnerungen, die Ihnen oder der Kohorte Ihres Kindes zugewiesen wurden."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über neue Aktivitäten"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen über neue Aktivitäten für Ihr Kind."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auswählen"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verarbeitung..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität erfolgreich gebucht, wartet auf Bestätigung."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Buchung für ", _interpolate(_named("activityName")), " für ", _interpolate(_named("childName")), " stornieren?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornierung bestätigen"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion bestätigen"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Aktion ausführen möchten?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung wurde storniert."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorten"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilen pro Seite"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier sehen Sie die Aktivitäten von"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bevorstehenden Sitzungen verfügbar"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre nächsten Ereignisse"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre offenen Buchungen"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung stornieren"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornierung bestätigen"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorte"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planer"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund auswählen"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihren Grund an"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier Ihren Grund ein..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankmeldung"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht auswählen"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsdatum"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten filtern"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aktivitäten"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Aktivitäten"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Aktivitäten"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zukünftige Aktivitäten"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter auswählen"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Name suchen"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehende Sitzungen"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühere Sitzungen"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsdetails werden geladen..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgesagt"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschoben"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplant"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planer"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kinder"])},
  "selectChild_de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind auswählen"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Derzeit sind keine Aktivitäten für ", _interpolate(_named("childName")), " verfügbar. Bitte schauen Sie später noch einmal vorbei."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Erinnerungen"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte Erinnerungen ausschließen"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen konnten nicht abgerufen werden"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Titel"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Beschreibung verfügbar"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Datum"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie wirklich ", _interpolate(_named("count")), " Benutzer löschen?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Abwesenheit"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesend"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), "'s Abwesenheit für einen Tag melden"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit bestätigen"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Abwesenheit dieses Schülers für den ausgewählten Tag melden möchten?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit erfolgreich für den ausgewählten Tag gemeldet."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abwesenheit konnte nicht gemeldet werden. Bitte versuchen Sie es erneut."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie sowohl ein Datum als auch einen gültigen Grund an."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit rückgängig machen"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit für die Sitzung melden"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund eingeben"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie Ihren Grund"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier Ihren Grund ein..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankheit"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannte Aktivität"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Ort"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kalender hinzufügen"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit rückgängig machen"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteroptionen"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum auswählen"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit erfolgreich gemeldet"])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Buchungen gefunden. Entdecken Sie Aktivitäten im Planer und buchen Sie jetzt!"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails sind momentan nicht verfügbar."])},
  "childAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung über die Abwesenheit des Kindes"])},
  "childAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen, wenn ein Kind als abwesend markiert wird."])},
  "childSessionAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung über das Fehlen des Kindes in einer Sitzung"])},
  "childSessionAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen, wenn ein Kind als abwesend für eine bestimmte Sitzung markiert wird."])},
  "allDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "admin.bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
  "admin.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
  "admin.newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu (7T)"])},
  "admin.bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Buchungsfreigabecenter"])},
  "admin.absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Abwesenheiten"])},
  "admin.childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Kinder"])},
  "admin.attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmeberichte"])},
  "admin.totalParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtteilnehmer:"])},
  "admin.attendanceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmerate:"])},
  "admin.childrenExcused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinder entschuldigt:"])},
  "admin.noReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teilnahmeberichte verfügbar."])},
  "admin.liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live-Buchungsstatus"])},
  "admin.activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten-Promotion"])},
  "admin.spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Plätze verfügbar"])},
  "admin.sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung senden"])},
  "admin.viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Abwesenheiten anzeigen"])},
  "admin.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "status.upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehend"])},
  "status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "status.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufend"])}
}